<template>
    <div class="my-3 payment-card">
        <v-img width="48px" height="48px" src="@/assets/img/visa.png" contain></v-img>
        <div class="d-flex flex-column payment-card-info">
            <span class="font-weight-bold text-caption">**** **** **** 0998</span>
            <span class="text-caption">{{$t("paymentCardValidity")}} 09/2026</span>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .payment-card{
        display: grid;
        grid-template-columns: 48px 1fr;
        column-gap: 16px;
        align-items: center;
    }
</style>