<template>
    <div class="pa-5 d-flex flex-column align-center ticket-wrapper">
        <v-btn class="align-self-end" icon @click="$router.push({name: 'MyOrders', query: { padOnly: true }})">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="mb-5 align-self-center text-center text-h6 ticket-title">{{$t("ticketChargeFinished")}}</span>

        <div class="ticket">
            <div class="pa-2 ticket-elmt ticket-header">
                <span class="pa-1 text-caption">{{selectedPin.metadata.title}}</span>
                <span class="pa-1 text-caption">{{$t("ticketAuthorization", {orderNumber: ticket.orderNumber})}}</span>
            </div>
            <v-divider></v-divider>
            
            <div class="pa-2 ticket-elmt ticket-body">
                <span class="pa-1 pb-0 text-caption">{{$t("ticketElectricType")}} - Plugsurfing</span>
                <span class="pa-1 pb-0 text-caption">{{$t("ticketTotal")}}</span>

                <span class="pa-1 pt-0 font-weight-bold text-h6">{{ticket.basketProducts[0].qty}}kW <span class="text-caption">({{formatNumber(selectedPin.metadata.pricePerMinutes)}}€/min)</span></span>
                
                <span class="pa-1 pt-0 font-weight-bold text-h6">{{formatNumber(ticket.totalAmount)}}€</span>

                
                <template v-if="getComment(ticket) && getComment(ticket).discount">
    
                    <span class="pa-1 pb-0 text-caption">{{$t("ticketDiscount")}}</span>
                    
                    <span class="pa-1 pb-0 text-caption">60% (- {{formatNumber(ticket.totalAmount * 0.6)}}€)</span>
                </template>
                
                <template v-if="getComment(ticket).penalties">
                    <span class="pa-1 pb-0 text-caption">{{$t("ticketPenalties")}}</span>
                    <span class="pa-1 pb-0 text-caption"></span>
    
                    <span class="pa-1 pt-0 font-weight-bold text-body-1">10 mins</span>
                    <span class="pa-1 pt-0 font-weight-bold text-body-1">1.51€</span>
                </template>

                <span class="pa-1 pt-0 font-weight-bold text-h6">{{$t("ticketTotal")}}</span>
                
                <span v-if="getComment(ticket) && getComment(ticket).discount" class="pa-1 pt-0 font-weight-bold text-h6">{{formatNumber(ticket.totalAmount * 0.4 + (getComment(ticket).penalties ? 1.51 : 0))}}€</span>
                <span v-else class="pa-1 pt-0 font-weight-bold text-h6">{{formatNumber(ticket.totalAmount + (getComment(ticket).penalties ? 1.51 : 0))}}€</span>
            </div>
            
            <v-divider></v-divider>
            <PaymentCard />
        </div>
        <div class="mt-8 d-flex flex-column align-center rating">
            <span class="font-weight-bold text-subtitle-1">{{$t("ticketRating")}}</span>
            <v-rating hover length="5" v-model="rating" size="32"></v-rating>
        </div>
        <v-btn color="primary" class="mt-4" :disabled="!validBtnEnabled" small @click="$router.push({name: 'MyOrders', query: { padOnly: true }})" >{{$t("ticketValidateRatingBtnLabel")}}</v-btn>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"
    import PaymentCard from "@/components/PaymentCard.vue"
    export default {
        components: {
            PaymentCard
        },
        data(){
            return {
                map: null,
                page: {
                    title: "ticketPageTitle",
                    description: null
                },
                rating: null
            }
        },
        methods: {
            getComment(ticket) {
                return JSON.parse(ticket.comment)
            }
        },
        computed:{
            ...mapGetters({
                ticket: "basket/lastTicket",
                selectedPin: "bookingDialog/selectedPin"
            }),
            validBtnEnabled(){
                return this.rating != null
            }
        }
        
    }
</script>

<style scoped>
    .ticket-elmt {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-items: start;
        column-gap: 16px;
    }
</style>